import React from 'react';
import { Button, Col } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PageNotFound = ({ match }) => {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <>
            <div className="h-100 bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col md="6" className="mx-auto app-login-box">
                        <div
                            className="mx-auto mb-3"
                            style={{
                                width: '100%',
                                display: 'block',
                                textAlign: 'center',
                                color: '#606d7b',
                            }}
                        >
                            <h4 style={{ marginBottom: 0, fontWeight: 500 }}>
                                <i className="far fa-sad-tear" /> {t('ERROR')}
                            </h4>
                            <h1
                                style={{
                                    fontSize: '8rem',
                                    fontWeight: 'bold',
                                    paddingBottom: '2rem',
                                    marginBottom: '2rem',
                                    borderBottom: '1px solid',
                                }}
                            >
                                404
                            </h1>
                            <h4 style={{ fontWeight: 500 }}>{t('Page Not Found')}</h4>
                            <div>
                                <Link to={'/'}>
                                    <Button
                                        size={'lg'}
                                        className={'btn-secondary'}
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                            color: '#606d7b',
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            border: '2px solid #606d7b',
                                        }}
                                    >
                                        {t('Home')}
                                    </Button>
                                </Link>
                                <Button
                                    size={'lg'}
                                    className={'ml-4 btn-secondary'}
                                    style={{
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        color: '#606d7b',
                                        backgroundColor: 'rgba(0, 0, 0, 0)',
                                        border: '2px solid #606d7b',
                                    }}
                                    onClick={e => {
                                        e.preventDefault();
                                        history.goBack();
                                    }}
                                >
                                    {t('Back')}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
        </>
    );
};

export default PageNotFound;
