import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bookmarkedMenu: [],
};

const { actions, reducer } = createSlice({
    name: 'customMenu',
    initialState,
    reducers: {
        setBookmarkMenu: (state, action) => {
            state.bookmarkedMenu = [...action.payload].sort((a, b) => a.order - b.order);
        },
    },
});

export const { setBookmarkMenu } = actions;
export default reducer;
