const IMAGES = {
    ACCELER_X_AXIS_CRITICAL: require('../../../../assets/images/sensor/ACCELER_X_AXIS_CRITICAL.png'),
    ACCELER_X_AXIS_NORMAL: require('../../../../assets/images/sensor/ACCELER_X_AXIS_NORMAL.png'),
    ACCELER_X_AXIS_null: require('../../../../assets/images/sensor/ACCELER_X_AXIS_null.png'),
    ACCELER_X_AXIS_OUTOFRANGE: require('../../../../assets/images/sensor/ACCELER_X_AXIS_OUTOFRANGE.png'),
    ACCELER_X_AXIS_WARNING: require('../../../../assets/images/sensor/ACCELER_X_AXIS_WARNING.png'),

    ACCELER_Y_AXIS_CRITICAL: require('../../../../assets/images/sensor/ACCELER_Y_AXIS_CRITICAL.png'),
    ACCELER_Y_AXIS_NORMAL: require('../../../../assets/images/sensor/ACCELER_Y_AXIS_NORMAL.png'),
    ACCELER_Y_AXIS_null: require('../../../../assets/images/sensor/ACCELER_Y_AXIS_null.png'),
    ACCELER_Y_AXIS_OUTOFRANGE: require('../../../../assets/images/sensor/ACCELER_Y_AXIS_OUTOFRANGE.png'),
    ACCELER_Y_AXIS_WARNING: require('../../../../assets/images/sensor/ACCELER_Y_AXIS_WARNING.png'),

    ACCELER_Z_AXIS_CRITICAL: require('../../../../assets/images/sensor/ACCELER_Z_AXIS_CRITICAL.png'),
    ACCELER_Z_AXIS_NORMAL: require('../../../../assets/images/sensor/ACCELER_Z_AXIS_NORMAL.png'),
    ACCELER_Z_AXIS_null: require('../../../../assets/images/sensor/ACCELER_Z_AXIS_null.png'),
    ACCELER_Z_AXIS_OUTOFRANGE: require('../../../../assets/images/sensor/ACCELER_Z_AXIS_OUTOFRANGE.png'),
    ACCELER_Z_AXIS_WARNING: require('../../../../assets/images/sensor/ACCELER_Z_AXIS_WARNING.png'),

    ANBIENT_LIGHT_CRITICAL: require('../../../../assets/images/sensor/ANBIENT_LIGHT_CRITICAL.png'),
    ANBIENT_LIGHT_NORMAL: require('../../../../assets/images/sensor/ANBIENT_LIGHT_NORMAL.png'),
    ANBIENT_LIGHT_null: require('../../../../assets/images/sensor/ANBIENT_LIGHT_null.png'),
    ANBIENT_LIGHT_OUTOFRANGE: require('../../../../assets/images/sensor/ANBIENT_LIGHT_OUTOFRANGE.png'),
    ANBIENT_LIGHT_WARNING: require('../../../../assets/images/sensor/ANBIENT_LIGHT_WARNING.png'),

    ANGULAR_X_AXIS_CRITICAL: require('../../../../assets/images/sensor/ANGULAR_X_AXIS_CRITICAL.png'),
    ANGULAR_X_AXIS_NORMAL: require('../../../../assets/images/sensor/ANGULAR_X_AXIS_NORMAL.png'),
    ANGULAR_X_AXIS_null: require('../../../../assets/images/sensor/ANGULAR_X_AXIS_null.png'),
    ANGULAR_X_AXIS_WARNING: require('../../../../assets/images/sensor/ANGULAR_X_AXIS_WARNING.png'),
    ANGULAR_X_AXIS_OUTOFRANGE: require('../../../../assets/images/sensor/ANGULAR_X_AXIS_OUTOFRANGE.png'),

    ANGULAR_Y_AXIS_CRITICAL: require('../../../../assets/images/sensor/ANGULAR_Y_AXIS_CRITICAL.png'),
    ANGULAR_Y_AXIS_NORMAL: require('../../../../assets/images/sensor/ANGULAR_Y_AXIS_NORMAL.png'),
    ANGULAR_Y_AXIS_null: require('../../../../assets/images/sensor/ANGULAR_Y_AXIS_null.png'),
    ANGULAR_Y_AXIS_WARNING: require('../../../../assets/images/sensor/ANGULAR_Y_AXIS_WARNING.png'),
    ANGULAR_Y_AXIS_OUTOFRANGE: require('../../../../assets/images/sensor/ANGULAR_Y_AXIS_OUTOFRANGE.png'),

    ANGULAR_Z_AXIS_CRITICAL: require('../../../../assets/images/sensor/ANGULAR_Z_AXIS_CRITICAL.png'),
    ANGULAR_Z_AXIS_NORMAL: require('../../../../assets/images/sensor/ANGULAR_Z_AXIS_NORMAL.png'),
    ANGULAR_Z_AXIS_null: require('../../../../assets/images/sensor/ANGULAR_Z_AXIS_null.png'),
    ANGULAR_Z_AXIS_OUTOFRANGE: require('../../../../assets/images/sensor/ANGULAR_Z_AXIS_OUTOFRANGE.png'),
    ANGULAR_Z_AXIS_WARNING: require('../../../../assets/images/sensor/ANGULAR_Z_AXIS_WARNING.png'),

    BAROM_PRESURE_CRITICAL: require('../../../../assets/images/sensor/BAROM_PRESURE_CRITICAL.png'),
    BAROM_PRESURE_NORMAL: require('../../../../assets/images/sensor/BAROM_PRESURE_NORMAL.png'),
    BAROM_PRESURE_null: require('../../../../assets/images/sensor/BAROM_PRESURE_null.png'),
    BAROM_PRESURE_WARNING: require('../../../../assets/images/sensor/BAROM_PRESURE_WARNING.png'),
    BAROM_PRESURE_OUTOFRANGE: require('../../../../assets/images/sensor/BAROM_PRESURE_OUTOFRANGE.png'),

    BATTERY_CRITICAL: require('../../../../assets/images/sensor/BATTERY_CRITICAL.png'),
    BATTERY_NORMAL: require('../../../../assets/images/sensor/BATTERY_NORMAL.png'),
    BATTERY_null: require('../../../../assets/images/sensor/BATTERY_null.png'),
    BATTERY_OUTOFRANGE: require('../../../../assets/images/sensor/BATTERY_OUTOFRANGE.png'),
    BATTERY_WARNING: require('../../../../assets/images/sensor/BATTERY_WARNING.png'),

    BODY_TEMP_CRITICAL: require('../../../../assets/images/sensor/BODY_TEMP_CRITICAL.png'),
    BODY_TEMP_NORMAL: require('../../../../assets/images/sensor/BODY_TEMP_NORMAL.png'),
    BODY_TEMP_null: require('../../../../assets/images/sensor/BODY_TEMP_null.png'),
    BODY_TEMP_OUTOFRANGE: require('../../../../assets/images/sensor/BODY_TEMP_OUTOFRANGE.png'),
    BODY_TEMP_WARNING: require('../../../../assets/images/sensor/BODY_TEMP_WARNING.png'),

    BUTTON1_COUNT_CRITICAL: require('../../../../assets/images/sensor/BUTTON1_COUNT_CRITICAL.png'),
    BUTTON1_COUNT_NORMAL: require('../../../../assets/images/sensor/BUTTON1_COUNT_NORMAL.png'),
    BUTTON1_COUNT_null: require('../../../../assets/images/sensor/BUTTON1_COUNT_null.png'),
    BUTTON1_COUNT_WARNING: require('../../../../assets/images/sensor/BUTTON1_COUNT_WARNING.png'),
    BUTTON1_COUNT_OUTOFRANGE: require('../../../../assets/images/sensor/BUTTON1_COUNT_OUTOFRANGE.png'),

    BUTTON2_COUNT_CRITICAL: require('../../../../assets/images/sensor/BUTTON2_COUNT_CRITICAL.png'),
    BUTTON2_COUNT_NORMAL: require('../../../../assets/images/sensor/BUTTON2_COUNT_NORMAL.png'),
    BUTTON2_COUNT_null: require('../../../../assets/images/sensor/BUTTON2_COUNT_null.png'),
    BUTTON2_COUNT_WARNING: require('../../../../assets/images/sensor/BUTTON2_COUNT_WARNING.png'),
    BUTTON2_COUNT_OUTOFRANGE: require('../../../../assets/images/sensor/BUTTON2_COUNT_OUTOFRANGE.png'),

    CALORIES_CRITICAL: require('../../../../assets/images/sensor/CALORIES_CRITICAL.png'),
    CALORIES_NORMAL: require('../../../../assets/images/sensor/CALORIES_NORMAL.png'),
    CALORIES_null: require('../../../../assets/images/sensor/CALORIES_null.png'),
    CALORIES_OUTOFRANGE: require('../../../../assets/images/sensor/CALORIES_OUTOFRANGE.png'),
    CALORIES_WARNING: require('../../../../assets/images/sensor/CALORIES_WARNING.png'),

    CAPACITY_CRITICAL: require('../../../../assets/images/sensor/CAPACITY_CRITICAL.png'),
    CAPACITY_NORMAL: require('../../../../assets/images/sensor/CAPACITY_NORMAL.png'),
    CAPACITY_null: require('../../../../assets/images/sensor/CAPACITY_null.png'),
    CAPACITY_OUTOFRANGE: require('../../../../assets/images/sensor/CAPACITY_OUTOFRANGE.png'),
    CAPACITY_WARNING: require('../../../../assets/images/sensor/CAPACITY_WARNING.png'),

    CO2_CRITICAL: require('../../../../assets/images/sensor/CO2_CRITICAL.png'),
    CO2_NORMAL: require('../../../../assets/images/sensor/CO2_NORMAL.png'),
    CO2_null: require('../../../../assets/images/sensor/CO2_null.png'),
    CO2_OUTOFRANGE: require('../../../../assets/images/sensor/CO2_OUTOFRANGE.png'),
    CO2_WARNING: require('../../../../assets/images/sensor/CO2_WARNING.png'),

    CO_CRITICAL: require('../../../../assets/images/sensor/CO_CRITICAL.png'),
    CO_NORMAL: require('../../../../assets/images/sensor/CO_NORMAL.png'),
    CO_null: require('../../../../assets/images/sensor/CO_null.png'),
    CO_OUTOFRANGE: require('../../../../assets/images/sensor/CO_OUTOFRANGE.png'),
    CO_WARNING: require('../../../../assets/images/sensor/CO_WARNING.png'),

    COMMON_CRITICAL: require('../../../../assets/images/sensor/COMMON_CRITICAL.png'),
    COMMON_NORMAL: require('../../../../assets/images/sensor/COMMON_NORMAL.png'),
    COMMON_null: require('../../../../assets/images/sensor/COMMON_null.png'),
    COMMON_OUTOFRANGE: require('../../../../assets/images/sensor/COMMON_OUTOFRANGE.png'),
    COMMON_WARNING: require('../../../../assets/images/sensor/COMMON_WARNING.png'),

    CRACK_CRITICAL: require('../../../../assets/images/sensor/CRACK_CRITICAL.png'),
    CRACK_NORMAL: require('../../../../assets/images/sensor/CRACK_NORMAL.png'),
    CRACK_null: require('../../../../assets/images/sensor/CRACK_null.png'),
    CRACK_OUTOFRANGE: require('../../../../assets/images/sensor/CRACK_OUTOFRANGE.png'),
    CRACK_WARNING: require('../../../../assets/images/sensor/CRACK_WARNING.png'),

    DISCOMFORT_IDX_CRITICAL: require('../../../../assets/images/sensor/DISCOMFORT_IDX_CRITICAL.png'),
    DISCOMFORT_IDX_NORMAL: require('../../../../assets/images/sensor/DISCOMFORT_IDX_NORMAL.png'),
    DISCOMFORT_IDX_null: require('../../../../assets/images/sensor/DISCOMFORT_IDX_null.png'),
    DISCOMFORT_IDX_WARNING: require('../../../../assets/images/sensor/DISCOMFORT_IDX_WARNING.png'),
    DISCOMFORT_IDX_OUTOFRANGE: require('../../../../assets/images/sensor/DISCOMFORT_IDX_OUTOFRANGE.png'),

    DISTANCE_CRITICAL: require('../../../../assets/images/sensor/DISTANCE_CRITICAL.png'),
    DISTANCE_NORMAL: require('../../../../assets/images/sensor/DISTANCE_NORMAL.png'),
    DISTANCE_null: require('../../../../assets/images/sensor/DISTANCE_null.png'),
    DISTANCE_OUTOFRANGE: require('../../../../assets/images/sensor/DISTANCE_OUTOFRANGE.png'),
    DISTANCE_WARNING: require('../../../../assets/images/sensor/DISTANCE_WARNING.png'),

    DOSAGE_CRITICAL: require('../../../../assets/images/sensor/DOSAGE_CRITICAL.png'),
    DOSAGE_NORMAL: require('../../../../assets/images/sensor/DOSAGE_NORMAL.png'),
    DOSAGE_null: require('../../../../assets/images/sensor/DOSAGE_null.png'),
    DOSAGE_OUTOFRANGE: require('../../../../assets/images/sensor/DOSAGE_OUTOFRANGE.png'),
    DOSAGE_WARNING: require('../../../../assets/images/sensor/DOSAGE_WARNING.png'),

    DROP_COUNT_CRITICAL: require('../../../../assets/images/sensor/DROP_COUNT_CRITICAL.png'),
    DROP_COUNT_NORMAL: require('../../../../assets/images/sensor/DROP_COUNT_NORMAL.png'),
    DROP_COUNT_null: require('../../../../assets/images/sensor/DROP_COUNT_null.png'),
    DROP_COUNT_OUTOFRANGE: require('../../../../assets/images/sensor/DROP_COUNT_OUTOFRANGE.png'),
    DROP_COUNT_WARNING: require('../../../../assets/images/sensor/DROP_COUNT_WARNING.png'),

    DROP_RATE_CRITICAL: require('../../../../assets/images/sensor/DROP_RATE_CRITICAL.png'),
    DROP_RATE_NORMAL: require('../../../../assets/images/sensor/DROP_RATE_NORMAL.png'),
    DROP_RATE_null: require('../../../../assets/images/sensor/DROP_RATE_null.png'),
    DROP_RATE_OUTOFRANGE: require('../../../../assets/images/sensor/DROP_RATE_OUTOFRANGE.png'),
    DROP_RATE_WARNING: require('../../../../assets/images/sensor/DROP_RATE_WARNING.png'),

    DUST_CRITICAL: require('../../../../assets/images/sensor/DUST_CRITICAL.png'),
    DUST_NORMAL: require('../../../../assets/images/sensor/DUST_NORMAL.png'),
    DUST_null: require('../../../../assets/images/sensor/DUST_null.png'),
    DUST_OUTOFRANGE: require('../../../../assets/images/sensor/DUST_OUTOFRANGE.png'),
    DUST_WARNING: require('../../../../assets/images/sensor/DUST_WARNING.png'),

    ECO2_CRITICAL: require('../../../../assets/images/sensor/ECO2_CRITICAL.png'),
    ECO2_NORMAL: require('../../../../assets/images/sensor/ECO2_NORMAL.png'),
    ECO2_null: require('../../../../assets/images/sensor/ECO2_null.png'),
    ECO2_OUTOFRANGE: require('../../../../assets/images/sensor/ECO2_OUTOFRANGE.png'),
    ECO2_WARNING: require('../../../../assets/images/sensor/ECO2_WARNING.png'),

    ETVOC_CRITICAL: require('../../../../assets/images/sensor/ETVOC_CRITICAL.png'),
    ETVOC_NORMAL: require('../../../../assets/images/sensor/ETVOC_NORMAL.png'),
    ETVOC_null: require('../../../../assets/images/sensor/ETVOC_null.png'),
    ETVOC_OUTOFRANGE: require('../../../../assets/images/sensor/ETVOC_OUTOFRANGE.png'),
    ETVOC_WARNING: require('../../../../assets/images/sensor/ETVOC_WARNING.png'),

    H2S_CRITICAL: require('../../../../assets/images/sensor/H2S_CRITICAL.png'),
    H2S_NORMAL: require('../../../../assets/images/sensor/H2S_NORMAL.png'),
    H2S_null: require('../../../../assets/images/sensor/H2S_null.png'),
    H2S_OUTOFRANGE: require('../../../../assets/images/sensor/H2S_OUTOFRANGE.png'),
    H2S_WARNING: require('../../../../assets/images/sensor/H2S_WARNING.png'),

    H_BLOODPRESSURE_CRITICAL: require('../../../../assets/images/sensor/H_BLOODPRESSURE_CRITICAL.png'),
    H_BLOODPRESSURE_NORMAL: require('../../../../assets/images/sensor/H_BLOODPRESSURE_NORMAL.png'),
    H_BLOODPRESSURE_null: require('../../../../assets/images/sensor/H_BLOODPRESSURE_null.png'),
    H_BLOODPRESSURE_WARNING: require('../../../../assets/images/sensor/H_BLOODPRESSURE_WARNING.png'),
    H_BLOODPRESSURE_OUTOFRANGE: require('../../../../assets/images/sensor/H_BLOODPRESSURE_OUTOFRANGE.png'),

    HEARTBEAT_CRITICAL: require('../../../../assets/images/sensor/HEARTBEAT_CRITICAL.png'),
    HEARTBEAT_NORMAL: require('../../../../assets/images/sensor/HEARTBEAT_NORMAL.png'),
    HEARTBEAT_null: require('../../../../assets/images/sensor/HEARTBEAT_null.png'),
    HEARTBEAT_OUTOFRANGE: require('../../../../assets/images/sensor/HEARTBEAT_OUTOFRANGE.png'),
    HEARTBEAT_WARNING: require('../../../../assets/images/sensor/HEARTBEAT_WARNING.png'),

    HEAT_STROKE_CRITICAL: require('../../../../assets/images/sensor/HEAT_STROKE_CRITICAL.png'),
    HEAT_STROKE_NORMAL: require('../../../../assets/images/sensor/HEAT_STROKE_NORMAL.png'),
    HEAT_STROKE_null: require('../../../../assets/images/sensor/HEAT_STROKE_null.png'),
    HEAT_STROKE_OUTOFRANGE: require('../../../../assets/images/sensor/HEAT_STROKE_OUTOFRANGE.png'),
    HEAT_STROKE_WARNING: require('../../../../assets/images/sensor/HEAT_STROKE_WARNING.png'),

    HEIGHT_CRITICAL: require('../../../../assets/images/sensor/HEIGHT_CRITICAL.png'),
    HEIGHT_NORMAL: require('../../../../assets/images/sensor/HEIGHT_NORMAL.png'),
    HEIGHT_null: require('../../../../assets/images/sensor/HEIGHT_null.png'),
    HEIGHT_OUTOFRANGE: require('../../../../assets/images/sensor/HEIGHT_OUTOFRANGE.png'),
    HEIGHT_WARNING: require('../../../../assets/images/sensor/HEIGHT_WARNING.png'),

    HUMIDITY_CRITICAL: require('../../../../assets/images/sensor/HUMIDITY_CRITICAL.png'),
    HUMIDITY_NORMAL: require('../../../../assets/images/sensor/HUMIDITY_NORMAL.png'),
    HUMIDITY_null: require('../../../../assets/images/sensor/HUMIDITY_null.png'),
    HUMIDITY_OUTOFRANGE: require('../../../../assets/images/sensor/HUMIDITY_OUTOFRANGE.png'),
    HUMIDITY_WARNING: require('../../../../assets/images/sensor/HUMIDITY_WARNING.png'),

    L_BLOODPRESSURE_CRITICAL: require('../../../../assets/images/sensor/L_BLOODPRESSURE_CRITICAL.png'),
    L_BLOODPRESSURE_NORMAL: require('../../../../assets/images/sensor/L_BLOODPRESSURE_NORMAL.png'),
    L_BLOODPRESSURE_null: require('../../../../assets/images/sensor/L_BLOODPRESSURE_null.png'),
    L_BLOODPRESSURE_WARNING: require('../../../../assets/images/sensor/L_BLOODPRESSURE_WARNING.png'),
    L_BLOODPRESSURE_OUTOFRANGE: require('../../../../assets/images/sensor/L_BLOODPRESSURE_OUTOFRANGE.png'),

    LEL_CRITICAL: require('../../../../assets/images/sensor/LEL_CRITICAL.png'),
    LEL_NORMAL: require('../../../../assets/images/sensor/LEL_NORMAL.png'),
    LEL_null: require('../../../../assets/images/sensor/LEL_null.png'),
    LEL_OUTOFRANGE: require('../../../../assets/images/sensor/LEL_OUTOFRANGE.png'),
    LEL_WARNING: require('../../../../assets/images/sensor/LEL_WARNING.png'),

    MOVEMENT_CRITICAL: require('../../../../assets/images/sensor/MOVEMENT_CRITICAL.png'),
    MOVEMENT_NORMAL: require('../../../../assets/images/sensor/MOVEMENT_NORMAL.png'),
    MOVEMENT_null: require('../../../../assets/images/sensor/MOVEMENT_null.png'),
    MOVEMENT_OUTOFRANGE: require('../../../../assets/images/sensor/MOVEMENT_OUTOFRANGE.png'),
    MOVEMENT_WARNING: require('../../../../assets/images/sensor/MOVEMENT_WARNING.png'),

    NAPPY_BIG_CRITICAL: require('../../../../assets/images/sensor/NAPPY_BIG_CRITICAL.png'),
    NAPPY_BIG_NORMAL: require('../../../../assets/images/sensor/NAPPY_BIG_NORMAL.png'),
    NAPPY_BIG_null: require('../../../../assets/images/sensor/NAPPY_BIG_null.png'),
    NAPPY_BIG_OUTOFRANGE: require('../../../../assets/images/sensor/NAPPY_BIG_OUTOFRANGE.png'),
    NAPPY_BIG_WARNING: require('../../../../assets/images/sensor/NAPPY_BIG_WARNING.png'),

    NAPPY_GAS_CRITICAL: require('../../../../assets/images/sensor/NAPPY_GAS_CRITICAL.png'),
    NAPPY_GAS_NORMAL: require('../../../../assets/images/sensor/NAPPY_GAS_NORMAL.png'),
    NAPPY_GAS_null: require('../../../../assets/images/sensor/NAPPY_GAS_null.png'),
    NAPPY_GAS_OUTOFRANGE: require('../../../../assets/images/sensor/NAPPY_GAS_OUTOFRANGE.png'),
    NAPPY_GAS_WARNING: require('../../../../assets/images/sensor/NAPPY_GAS_WARNING.png'),

    NAPPY_SMALL_CRITICAL: require('../../../../assets/images/sensor/NAPPY_SMALL_CRITICAL.png'),
    NAPPY_SMALL_NORMAL: require('../../../../assets/images/sensor/NAPPY_SMALL_NORMAL.png'),
    NAPPY_SMALL_null: require('../../../../assets/images/sensor/NAPPY_SMALL_null.png'),
    NAPPY_SMALL_OUTOFRANGE: require('../../../../assets/images/sensor/NAPPY_SMALL_OUTOFRANGE.png'),
    NAPPY_SMALL_WARNING: require('../../../../assets/images/sensor/NAPPY_SMALL_WARNING.png'),

    O2_CRITICAL: require('../../../../assets/images/sensor/O2_CRITICAL.png'),
    O2_NORMAL: require('../../../../assets/images/sensor/O2_NORMAL.png'),
    O2_null: require('../../../../assets/images/sensor/O2_null.png'),
    O2_OUTOFRANGE: require('../../../../assets/images/sensor/O2_OUTOFRANGE.png'),
    O2_WARNING: require('../../../../assets/images/sensor/O2_WARNING.png'),

    PACKET_COUNTER_CRITICAL: require('../../../../assets/images/sensor/PACKET_COUNTER_CRITICAL.png'),
    PACKET_COUNTER_NORMAL: require('../../../../assets/images/sensor/PACKET_COUNTER_NORMAL.png'),
    PACKET_COUNTER_null: require('../../../../assets/images/sensor/PACKET_COUNTER_null.png'),
    PACKET_COUNTER_WARNING: require('../../../../assets/images/sensor/PACKET_COUNTER_WARNING.png'),
    PACKET_COUNTER_OUTOFRANGE: require('../../../../assets/images/sensor/PACKET_COUNTER_OUTOFRANGE.png'),

    PIR_CRITICAL: require('../../../../assets/images/sensor/PIR_CRITICAL.png'),
    PIR_NORMAL: require('../../../../assets/images/sensor/PIR_NORMAL.png'),
    PIR_null: require('../../../../assets/images/sensor/PIR_null.png'),
    PIR_OUTOFRANGE: require('../../../../assets/images/sensor/PIR_OUTOFRANGE.png'),
    PIR_WARNING: require('../../../../assets/images/sensor/PIR_WARNING.png'),

    PULSE_RATE_CRITICAL: require('../../../../assets/images/sensor/PULSE_RATE_CRITICAL.png'),
    PULSE_RATE_NORMAL: require('../../../../assets/images/sensor/PULSE_RATE_NORMAL.png'),
    PULSE_RATE_null: require('../../../../assets/images/sensor/PULSE_RATE_null.png'),
    PULSE_RATE_OUTOFRANGE: require('../../../../assets/images/sensor/PULSE_RATE_OUTOFRANGE.png'),
    PULSE_RATE_WARNING: require('../../../../assets/images/sensor/PULSE_RATE_WARNING.png'),

    R_HUMIDITY_CRITICAL: require('../../../../assets/images/sensor/R_HUMIDITY_CRITICAL.png'),
    R_HUMIDITY_NORMAL: require('../../../../assets/images/sensor/R_HUMIDITY_NORMAL.png'),
    R_HUMIDITY_null: require('../../../../assets/images/sensor/R_HUMIDITY_null.png'),
    R_HUMIDITY_OUTOFRANGE: require('../../../../assets/images/sensor/R_HUMIDITY_OUTOFRANGE.png'),
    R_HUMIDITY_WARNING: require('../../../../assets/images/sensor/R_HUMIDITY_WARNING.png'),

    SEISMIC_INTENS_CRITICAL: require('../../../../assets/images/sensor/SEISMIC_INTENS_CRITICAL.png'),
    SEISMIC_INTENS_NORMAL: require('../../../../assets/images/sensor/SEISMIC_INTENS_NORMAL.png'),
    SEISMIC_INTENS_null: require('../../../../assets/images/sensor/SEISMIC_INTENS_null.png'),
    SEISMIC_INTENS_WARNING: require('../../../../assets/images/sensor/SEISMIC_INTENS_WARNING.png'),
    SEISMIC_INTENS_OUTOFRANGE: require('../../../../assets/images/sensor/SEISMIC_INTENS_OUTOFRANGE.png'),

    SLEEP_CRITICAL: require('../../../../assets/images/sensor/SLEEP_CRITICAL.png'),
    SLEEP_NORMAL: require('../../../../assets/images/sensor/SLEEP_NORMAL.png'),
    SLEEP_null: require('../../../../assets/images/sensor/SLEEP_null.png'),
    SLEEP_OUTOFRANGE: require('../../../../assets/images/sensor/SLEEP_OUTOFRANGE.png'),
    SLEEP_WARNING: require('../../../../assets/images/sensor/SLEEP_WARNING.png'),

    SO2_CRITICAL: require('../../../../assets/images/sensor/SO2_CRITICAL.png'),
    SO2_NORMAL: require('../../../../assets/images/sensor/SO2_NORMAL.png'),
    SO2_null: require('../../../../assets/images/sensor/SO2_null.png'),
    SO2_OUTOFRANGE: require('../../../../assets/images/sensor/SO2_OUTOFRANGE.png'),
    SO2_WARNING: require('../../../../assets/images/sensor/SO2_WARNING.png'),

    SOUND_NOISE_CRITICAL: require('../../../../assets/images/sensor/SOUND_NOISE_CRITICAL.png'),
    SOUND_NOISE_NORMAL: require('../../../../assets/images/sensor/SOUND_NOISE_NORMAL.png'),
    SOUND_NOISE_null: require('../../../../assets/images/sensor/SOUND_NOISE_null.png'),
    SOUND_NOISE_OUTOFRANGE: require('../../../../assets/images/sensor/SOUND_NOISE_OUTOFRANGE.png'),
    SOUND_NOISE_WARNING: require('../../../../assets/images/sensor/SOUND_NOISE_WARNING.png'),

    STEP_CRITICAL: require('../../../../assets/images/sensor/STEP_CRITICAL.png'),
    STEP_NORMAL: require('../../../../assets/images/sensor/STEP_NORMAL.png'),
    STEP_null: require('../../../../assets/images/sensor/STEP_null.png'),
    STEP_OUTOFRANGE: require('../../../../assets/images/sensor/STEP_OUTOFRANGE.png'),
    STEP_WARNING: require('../../../../assets/images/sensor/STEP_WARNING.png'),

    TEMPERATURE_CRITICAL: require('../../../../assets/images/sensor/TEMPERATURE_CRITICAL.png'),
    TEMPERATURE_NORMAL: require('../../../../assets/images/sensor/TEMPERATURE_NORMAL.png'),
    TEMPERATURE_null: require('../../../../assets/images/sensor/TEMPERATURE_null.png'),
    TEMPERATURE_OUTOFRANGE: require('../../../../assets/images/sensor/TEMPERATURE_OUTOFRANGE.png'),
    TEMPERATURE_WARNING: require('../../../../assets/images/sensor/TEMPERATURE_WARNING.png'),

    TOTAL_DOSAGE_CRITICAL: require('../../../../assets/images/sensor/TOTAL_DOSAGE_CRITICAL.png'),
    TOTAL_DOSAGE_NORMAL: require('../../../../assets/images/sensor/TOTAL_DOSAGE_NORMAL.png'),
    TOTAL_DOSAGE_null: require('../../../../assets/images/sensor/TOTAL_DOSAGE_null.png'),
    TOTAL_DOSAGE_OUTOFRANGE: require('../../../../assets/images/sensor/TOTAL_DOSAGE_OUTOFRANGE.png'),
    TOTAL_DOSAGE_WARNING: require('../../../../assets/images/sensor/TOTAL_DOSAGE_WARNING.png'),

    WEIGHT_CRITICAL: require('../../../../assets/images/sensor/WEIGHT_CRITICAL.png'),
    WEIGHT_NORMAL: require('../../../../assets/images/sensor/WEIGHT_NORMAL.png'),
    WEIGHT_null: require('../../../../assets/images/sensor/WEIGHT_null.png'),
    WEIGHT_OUTOFRANGE: require('../../../../assets/images/sensor/WEIGHT_OUTOFRANGE.png'),
    WEIGHT_WARNING: require('../../../../assets/images/sensor/WEIGHT_WARNING.png'),
};

export default IMAGES;
