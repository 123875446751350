const envKeys = {
    savedId: process.env.REACT_APP_SAVED_ID,
    userInfoKey: process.env.REACT_APP_USER_INFO_KEY,
    cryptoKey: process.env.REACT_APP_CRYPTO_KEY,
    cryptoIv: process.env.REACT_APP_CRYPTO_IV,
    filterInfoKey: process.env.REACT_APP_AT_FILTER_INFO_KEY,
    wmsApiUrl: process.env.REACT_APP_WMS_API_URL,
    wmsUrl: process.env.REACT_APP_WMS_URL,
    oAuthApiUrl: process.env.REACT_APP_OAUTH_API_URL,
};
Object.freeze(envKeys);
export default envKeys;
