import { useEffect, useReducer } from 'react';
import { checkResponseErr } from '../common/util';

const asyncInitialState = {
    keepState: false,
    isLoading: false,
    request: null,
    response: null,
    error: null,
};

function asyncReducer(state, action) {
    switch (action.type) {
        case 'LOADING':
            return {
                keepState: state.keepState,
                isLoading: true,
                request: action.payload.request,
                response: state.keepState ? state.response : null,
                error: state.keepState ? state.error : null,
            };
        case 'SUCCESS':
            return {
                keepState: state.keepState,
                isLoading: false,
                request: state.request,
                response: action.payload.response.data || action.payload.response,
                error: null,
            };
        case 'ERROR':
            return {
                keepState: state.keepState,
                isLoading: false,
                request: state.request,
                response: null,
                error: action.payload.error,
            };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

const useAsync = ({
    promise,
    param,
    fixedParam = {},
    deps = [],
    immediate = false,
    resolve,
    reject,
    keepState = false,
}) => {
    const [state, dispatch] = useReducer(asyncReducer, { ...asyncInitialState, keepState });

    const asyncPromise = async newParam => {
        const thisParam = { ...fixedParam, ...(newParam || param) };
        dispatch({ type: 'LOADING', payload: { request: thisParam } });
        try {
            if (Array.isArray(promise)) {
                const responseArr = [];
                const errResponseArr = [];
                for (let i = 0, len = promise.length; i < len; i++) {
                    const tempPromise = promise[i];
                    const tempParam = thisParam && thisParam[i];
                    const response = await tempPromise(tempParam);
                    if (checkResponseErr(response)) {
                        errResponseArr.push(response);
                    } else {
                        responseArr.push(response.data);
                    }
                }
                if (errResponseArr.length) {
                    dispatch({ type: 'ERROR', payload: { error: errResponseArr } });
                } else {
                    dispatch({ type: 'SUCCESS', payload: { response: responseArr } });
                }
            } else {
                const response = await promise(thisParam);
                if (checkResponseErr(response)) {
                    dispatch({ type: 'ERROR', payload: { error: response } });
                } else {
                    dispatch({ type: 'SUCCESS', payload: { response: response.data } });
                }
            }
        } catch (e) {
            dispatch({ type: 'ERROR', payload: { error: e } });
        }
    };

    useEffect(() => {
        if (immediate) {
            asyncPromise();
        }
    }, deps);

    useEffect(() => {
        if (!state.isLoading) {
            if (state.response && typeof resolve === 'function') {
                resolve(state.response, state.request);
            } else if (state.error !== null && typeof reject === 'function') {
                reject(state.error);
            }
        }
    }, [state]);

    return {
        state,
        promise: asyncPromise,
    };
};

export default useAsync;
