import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import Label from '../../../Common/Label';
import SingleSelect from '../../../Common/Select/SingleSelect';
import { editWidgetSettings } from '../../../../reducers/Dashboards/DashboardEdit';
import { useDispatch } from 'react-redux';
import { useSettings } from '../../util/useSettings';

const TopFiveCongestedGeofenceSetting = ({ widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings } = config;
    const options = useMemo(
        () => [
            { value: 'category', label: t('TopFiveCongestedGeofence;Category') },
            { value: 'restricted', label: t('TopFiveCongestedGeofence;Authorization') },
        ],
        [t],
    );
    const [selectedType, setSelectedType] = useState(null);

    const handleSelectChange = e => {
        setSelectedType(e);
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, type: selectedType },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, type: selectedType },
                }),
            );
        }
    };

    useEffect(() => {
        if (settings.type) {
            setSelectedType(settings.type);
        }
    }, [settings]);

    return (
        <WidgetSettingModal
            headerTitle={t('TopFiveCongestedGeofence;Congested Geofence Top 5')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            {...restProps}
        >
            <Label
                name={t('TopFiveCongestedGeofence;Type')}
                value={
                    <SingleSelect
                        className={'w-100'}
                        options={options}
                        value={selectedType}
                        onChange={handleSelectChange}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default TopFiveCongestedGeofenceSetting;
