import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editWidgetSettings } from '../../../reducers/Dashboards/DashboardEdit';
import { SingleFloor } from '../Components/WidgetSettingOptions';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import useTranslation from '../../../util/hooks/useTranslation';

const GeofenceStatusSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('GeofenceStatus');
    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const dispatch = useDispatch();

    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState({});

    const handleFloorChange = selected => {
        if (selected[0]) {
            setUpdatedWidgetInfo(prevState => {
                return { ...prevState, floor: selected[0].floorId };
            });
        }
    };

    const handleSubmitClick = data => {
        if (customSettings) {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...customSettings, ...data, ...updatedWidgetInfo },
                }),
            );
        } else {
            dispatch(
                editWidgetSettings({
                    id: widgetInfo.id,
                    settings: { ...data, ...updatedWidgetInfo },
                }),
            );
        }
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    return (
        <WidgetSettingModal
            headerTitle={t('Geofence Status Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <SingleFloor
                value={
                    updatedWidgetInfo.floor
                        ? updatedWidgetInfo.floor
                        : customSettings && customSettings.floor
                        ? customSettings.floor
                        : ''
                }
                onChange={handleFloorChange}
            />
        </WidgetSettingModal>
    );
};

export default GeofenceStatusSetting;
