import { useCallback } from 'react';
import { useTranslation as useTranslationOrigin } from 'react-i18next';

const useTranslation = ns => {
    const { t } = useTranslationOrigin();
    return useCallback(
        (text, overWriteNs = ns) => {
            return overWriteNs ? t(`${overWriteNs};${text}`) : t(text);
        },
        [t],
    );
};

export default useTranslation;