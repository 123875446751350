import React, { useState, useMemo, useEffect } from 'react';
import useTranslation from '../../../../util/hooks/useTranslation';
import { useSelector } from 'react-redux';
import TreeSelectWithOption from '../Components/SelectWithOption/TreeSelectWithOption';

const FloorSelect = ({ selected, isEditable, handleChange, disableOption }) => {
    const t = useTranslation('Filter');
    const [selectedOption, setSelectedOption] = useState(selected);

    const { floorList } = useSelector(state => state.FloorInfo);
    const treeData = useMemo(() => {
        return floorList.map(v => ({ ...v, floorIds: v.floorId }));
    }, [floorList]);

    const handleSelectedOptionChange = (selected, isEditable) => {
        handleChange(selected, isEditable);
        setSelectedOption(selected);
    };

    useEffect(() => {
        if (floorList.length && selected && selected.length) {
            setSelectedOption(selected);
        } else {
            setSelectedOption([]);
        }
    }, [selected, floorList]);

    return (
        <TreeSelectWithOption
            data={treeData}
            title={t('Floor')}
            valueKey={'floorId'}
            parentKey={'upperFloorId'}
            labelKey={'floorName'}
            selected={selectedOption}
            onChange={handleSelectedOptionChange}
            isEditable={isEditable}
            disableOption={disableOption}
        />
    );
};

export default FloorSelect;
