import React, { useContext } from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import { SearchableSelectContext } from '../SearchableSelect';
import cx from 'classnames';
import useTranslation from '../../../../util/hooks/useTranslation';

export const Menu = props => {
    return <div className={'select-menu'} {...props} />;
};
export const Blanket = props => <div className={'select-blanket'} {...props} />;
export const Dropdown = ({ className, children, isOpen, target, onClose }) => (
    <div className={cx(className, 'select-dropdown')}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
);
export const Svg = p => <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />;
export const DropdownIndicator = () => (
    <div className={'select-dropdown-indicator'}>
        <Svg>
            <path
                d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Svg>
    </div>
);

export const Option = function ({ label, isSelected, ...restProps }) {
    return (
        <div className={'select-option'}>
            <components.Option {...restProps}>
                <label className={'text-ellipsis mb-0 select-label w-100'} title={label}>
                    <input type="checkbox" checked={isSelected} onChange={e => null} />
                    {label}
                </label>
                {/*<label style={{ marginBottom: '4px' }}>{label}</label>*/}
            </components.Option>
        </div>
    );
};
export const Control = ({ children, options, ...restProps }) => {
    // console.log(restProps);
    return options.length > 10 ? (
        <components.Control
            className={'select-control'}
            {...restProps}
            options={options}
            // innerProps={{
            //     onMouseDown: e => {
            //         console.log('mousedown');
            //     },
            //     onTouchEnd: e => {
            //         console.log('touchend');
            //     },
            // }}
        >
            {children}
        </components.Control>
    ) : null;
};

export const StyledContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    min-width: 11.5rem;
    //max-width: 14rem;
    width: 100%;
    & > div:hover {
        border-color: hsl(0, 0%, 70%);
    }
    & > div {
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border: ${props => (props.isOpen ? '1px solid #2684ff' : '1px solid hsl(0, 0%, 80%)')};
        box-shadow: ${props => props.isOpen && '0 0 0 1px #2684ff'};
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;

        div:first-child {
            align-items: center;
            display: flex;
            flex: 1;
            flex-wrap: wrap;
            padding: 2px 8px;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
        }
        div:last-child {
            align-items: center;
            align-self: stretch;
            display: flex;
            flex-shrink: 0;
            span {
                align-self: stretch;
                background-color: hsl(0, 0%, 80%);
                margin-bottom: 8px;
                margin-top: 8px;
                width: 1px;
                box-sizing: border-box;
            }
            div {
                color: hsl(0, 0%, 80%);
                display: flex;
                padding: 8px;
                transition: color 150ms;
                box-sizing: border-box;
                svg {
                    display: inline-block;
                    fill: currentColor;
                    line-height: 1;
                    stroke: currentColor;
                    stroke-width: 0;
                }
            }
            div:hover {
                color: hsl(0, 0%, 60%);
            }
        }
    }
`;

export const ValueContainerText = ({ title, labelKey, selected, options }) => {
    const t = useTranslation('TreeSelect');
    const selectedLength = selected ? selected.length : 0;
    const optionsLength = options ? options.length : 0;
    return !selectedLength || selectedLength === optionsLength ? (
        <strong>
            {title} : {t('All')}
        </strong>
    ) : selectedLength === 1 ? (
        <strong>{selected[0][labelKey]}</strong>
    ) : (
        <>
            <strong>{selected[0][labelKey]}</strong>
            <span>
                &nbsp;{t('other')} {selectedLength - 1}
            </span>
        </>
    );
};

export const ValueContainer = ({ selected, onClick, isOpen, title, labelKey, options }) => {
    return (
        <StyledContainer onClick={onClick} isOpen={isOpen}>
            <div className={cx('select-container', isOpen && 'container-open')}>
                <div>
                    <ValueContainerText title={title} labelKey={labelKey} selected={selected} options={options} />
                </div>
                <div>
                    <div>
                        <svg
                            className={'select-dropdown-svg'}
                            height="16"
                            width="18"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                        >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                        </svg>
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
};

export const MenuList = ({ children, getValue, setValue, options, ...restProps }) => {
    const selected = getValue();
    const t = useTranslation('TreeSelect');
    const { valueKey, labelKey } = useContext(SearchableSelectContext);
    const handleChange = e => {
        const { checked } = e.currentTarget;
        setValue(checked ? options : []);
    };
    return (
        <components.MenuList {...restProps}>
            <div className={'mb-2'}>
                <div className={'select-group-title'}>{t('Selected')}</div>
                {selected.length ? (
                    selected.map(v => (
                        <div
                            className={'styled-option'}
                            key={v[valueKey]}
                            onClick={e => {
                                setValue(selected.filter(selectedOption => selectedOption[valueKey] !== v[valueKey]));
                            }}
                        >
                            <label className={'text-ellipsis w-100'} title={v[valueKey]}>
                                <input type="checkbox" value={v[valueKey]} checked onChange={e => null} />
                                {v[labelKey]}
                            </label>
                        </div>
                    ))
                ) : (
                    <div className={'styled-option-label'}>{t('Not Selected')}</div>
                )}
            </div>
            <div>
                <div className={'select-group-title pt-0 pb-0'}>
                    <label className={'text-ellipsis mb-0'} title="All Items">
                        <input type="checkbox" onChange={handleChange} checked={selected.length === options.length} />
                        {t('All')}
                    </label>
                </div>
                {children}
            </div>
        </components.MenuList>
    );
};
