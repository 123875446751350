import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useTranslation from '../../../../util/hooks/useTranslation';

const PermissionBarChart = ({ congestedGeofenceInfo }) => {
    const t = useTranslation('TopFiveCongestedGeofence');
    return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
            <BarChart
                data={congestedGeofenceInfo}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="fcName" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name={t('permitted')} dataKey="permitted" stackId="a" fill="#1F77B4" />
                <Bar name={t('restricted')} dataKey="restricted" stackId="a" fill="#FF7F0E" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default PermissionBarChart;
