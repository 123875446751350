import React from 'react';
import Card from '../Components/WidgetCard';
import useMonitor from '../util/useMonitor';
import { WindowDataGrid } from '../../Common/DataGrid/DataGrid';
import * as cols from '../Components/GridColumns';
import _ from 'lodash';

const columns = [
    { ...cols.patName, Header: '자산 이름' },
    { ...cols.patAge, Header: '자산 ID' },
    { ...cols.respiratoryRate, Header: '카테고리' },
    { ...cols.temperature, Header: '사용 여부' },
];

const defaultData = [
    {
        patNum: 1,
        patName: 'Asset1',
        patAge: 'a1',
        respiratoryRate: '심박수 측정기',
        temperature: 'O',
    },
];

const generateData = () => {
    return new Promise(function (resolve, reject) {
        const data = [];
        const disabled = _.random(7, 8);
        for (let i = 1; i < 57; i++) {
            data.push({
                patNum: i,
                patName: `Asset_${i}`,
                patAge: `a${i}`,
                respiratoryRate: _.sample(['심박수 측정기', '체온계', '산소 호흡기', '온도계']),
                temperature: i % disabled ? 'O' : 'X',
            });
        }
        resolve({
            data,
        });
    });
};

const AssetStatusGrid = ({ children, widgetInfo, ...restProps }) => {
    const { config } = widgetInfo;
    const { data } = useMonitor({
        config,
        defaultData,
        fetchData: generateData,
    });

    return (
        <>
            <Card widgetInfo={widgetInfo} {...restProps}>
                <WindowDataGrid
                    clickableRow={false}
                    columns={columns}
                    data={data}
                    pageSize={data.length}
                    showPagination={false}
                    style={{ height: '100%' }}
                />
                {children}
            </Card>
        </>
    );
};

export default AssetStatusGrid;
