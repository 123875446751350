import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isEditable: 'Y',
    disableOption: false,
};

const { actions, reducer } = createSlice({
    name: 'filterSelect',
    initialState,
    reducers: {
        setInitState: (state, action) => {
            const initData = action.payload || {};
            state.isEditable = initData.isEditable;
            state.disableOption = initData.disableOption;
        },
        setEditable: (state, action) => {
            state.isEditable = action.payload;
        },
    },
});

export const { setInitState, setEditable } = actions;
export default reducer;
