import React, { useCallback, useEffect, useState, useRef } from 'react';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

const TreeSelect = styled(DropdownTreeSelect)`
    .dropdown {
        width: 100%;
    }
    .dropdown-trigger {
        border-radius: 0.25rem;
        background-color: #fff;
        color: #495057;
        width: 100%;
    }
    .dropdown-trigger:hover {
        text-decoration: none !important;
    }
    .dropdown-trigger > span:after {
        font-size: 12px;
        color: #555;
    }
    .toggle {
        font-family: 'Font Awesome 5 Free';
        font-size: 0.88rem;
        color: #555;
        font-style: normal;
        vertical-align: middle;
    }
    .toggle.collapsed::after {
        content: '\f0fe';
    }
    .toggle.expanded::after {
        content: '\f146';
    }
    .root {
        padding: 0px;
        margin: 0px;
    }
    //.root .node.tree:first-child {
    //    padding-left: 4px !important;
    //}
    .node > label {
        margin-bottom: 0;
        width: 100%;
    }
    .dropdown-content {
        min-width: 100%;
        max-height: 300px;
        overflow-y: auto;
    }
    .tag-item > .tag {
        line-height: 16px;
    }
    .tag-item > input {
        border: none;
    }
    .node > label > input[type='radio'] {
        display: none;
    }
    .node.disabled > i {
        cursor: pointer;
        color: #555;
    }
    .search {
        width: 100%;
    }
    .tag-list .tag-item {
        line-height: 1;
        width: 80%;
    }

    .no-matches {
        display: inline-block;
        margin: 8px 4px;
    }

    .node > label {
        width: 95%;
        padding: 0.16rem 0;
    }

    .dropdown-trigger.arrow {
        &.bottom:after,
        &.top:after {
            //content: 'ᐯ' !important;
            color: hsl(0, 0%, 80%) !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }
    ${props =>
        ['simpleSelect', 'radioSelect'].find(v => v === props.mode) &&
        `
        .tag-list {
            vertical-align: middle;
        }
        .tag-list .tag-item {
            line-height: 1;
            width: 80%;
        }
        .tag-list .tag-item:not(:last-child) {
            display: none;
        }
        .tag-list .tag-item:last-child>span {
            width: 150px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
        }
        `}
`;

const findParentNode = (floorList, upperFloorId, parents = []) => {
    const node = floorList.find(v => v.floorId === upperFloorId);
    if (node && node.upperFloorId) {
        parents.push(node.upperFloorId);
        findParentNode(floorList, node.upperFloorId, parents);
    }
    return parents;
};

const findParentNodeSubRows = (path, searchList) => {
    if (path.length > 1) {
        const currPath = path.shift();
        return findParentNodeSubRows(path, searchList.find(node => node.floorId === currPath).children);
    } else {
        return searchList;
    }
};

const makeHierarchyFloorInfo = floorInfo => {
    const tempFloorInfo = cloneDeep(floorInfo);
    tempFloorInfo.sort((a, b) => a.levelNo - b.levelNo);
    return tempFloorInfo.reduce((acc, floor) => {
        const expandableFloor = {
            authorized: 0,
            unAuthorized: 0,
            ...floor,
            label: floor.floorName,
            value: floor.floorId,
            // checked: true,
            children: [],
        };
        if (floor.levelNo === 0) {
            acc.push(expandableFloor);
        } else {
            const path = floor.floorIdPath.split('>');
            const subRows = findParentNodeSubRows(path, acc);
            subRows.push(expandableFloor);
        }
        return acc;
    }, []);
};

const getExpandFloorBySelected = (floorList, selectedFloorList) => {
    let expandFloorBySelected = [];
    selectedFloorList.forEach(v => {
        const parents = findParentNode(floorList, v);
        expandFloorBySelected = expandFloorBySelected.concat(parents);
    });
    return expandFloorBySelected;
};

const makeSelectedName = (floorList, selectedFloorList) => {
    return selectedFloorList
        .reduce((acc, curr) => {
            const floor = floorList.find(floorInfo => floorInfo.floorId === curr);
            if (floor) {
                acc.push(floor.floorName);
            }
            return acc;
        }, [])
        .join(',');
};

const FloorTreeSelect = ({
    floorList,
    selectedFloorStr = '',
    innerRef,
    formName = 'floor',
    mode = 'multiSelect',
    handleChange,
    showDropdown,
}) => {
    const { t } = useTranslation();
    const treeDropdownRef = useRef();
    const origin = floorList;
    const [selectedFloor, setSelectedFloor] = useState(selectedFloorStr);
    const [selectedLabel, setSelectedLabel] = useState(makeSelectedName(origin, selectedFloor.split(',')));
    const [expandedFloor, setExpandedFloor] = useState(
        getExpandFloorBySelected(origin, selectedFloor.split(',')).reduce((acc, curr) => {
            acc[curr] = true;
            return acc;
        }, {}),
    );

    const [floorTreeList, setFloorTreeList] = useState(makeHierarchyFloorInfo(origin));
    const onChange = useCallback(
        ({ value, checked, _children, floorId, floorName, expanded }, selectedNodes) => {
            // if (value === 'selectAll') {
            //     setFloorTreeList(prev => {
            //         for (let i = 0; i < prev.length; i++) {
            //             prev[i].checked = checked;
            //         }
            //         console.log(prev);
            //         return prev;
            //     });
            // }
            // console.log({ value, checked, _children, floorId, floorName, expanded });
            // if (['simpleSelect', 'radioSelect'].find(v => v === mode)) {
            //     let selectedFloorId = '';
            //     if (_children.length === 0) {
            //         selectedFloorId = floorId;
            //         setSingleSelectLabel(floorName);
            //     } else {
            //         setExpandedFloor(prev => {
            //             prev[floorId] = !expanded;
            //             return prev;
            //         });
            //     }
            //     setSelectedFloor(selectedFloorId);
            // } else {
            //     setSelectedFloor(selectedNodes.map(v => v.floorId).join(','));
            // }
            setSelectedFloor(selectedNodes.map(v => v.floorId).join(','));
            if (typeof handleChange === 'function') {
                handleChange(selectedNodes);
            }
        },
        [handleChange],
    );
    const onNodeToggle = useCallback(
        currentNode => {
            // console.log('onNodeToggle::', currentNode);
            setExpandedFloor(prev => {
                prev[currentNode.floorId] = currentNode.expanded;
                return prev;
            });
            const isSingleSelect = ['simpleSelect', 'radioSelect'].find(v => v === mode);
            const selectedFloorList = selectedFloor.split(',');
            setFloorTreeList(
                makeHierarchyFloorInfo(
                    origin.map(v => {
                        const copyV = { ...v };
                        if (selectedFloorList.includes(v.floorId)) {
                            copyV.checked = true;
                        }
                        copyV.expanded = expandedFloor[copyV.floorId];
                        const hasChildren = origin.find(floor => floor.upperFloorId === copyV.floorId);
                        copyV.disabled = !!(isSingleSelect && hasChildren);
                        return copyV;
                    }),
                ),
            );
        },
        [mode, origin],
    );

    const handleMouseWheelEvent = function (e) {
        e.stopPropagation();
    };

    useEffect(() => {
        if (!selectedFloorStr && floorList.length > 0) {
            setSelectedFloor(floorList[0].floorId);
            handleChange([floorList[0]]);
        }

        if (treeDropdownRef.current) {
            treeDropdownRef.current.node.addEventListener('wheel', handleMouseWheelEvent);
        }
        return () => {
            if (treeDropdownRef.current) {
                treeDropdownRef.current.node.removeEventListener('wheel', handleMouseWheelEvent);
            }
        };
    }, []);
    useEffect(() => {
        const isSingleSelect = ['simpleSelect', 'radioSelect'].find(v => v === mode);
        const selectedFloorList = selectedFloor.split(',');
        setFloorTreeList(
            makeHierarchyFloorInfo(
                origin.map(v => {
                    const copyV = { ...v };
                    if (selectedFloorList.includes(v.floorId)) {
                        copyV.checked = true;
                    }
                    copyV.expanded = expandedFloor[copyV.floorId];
                    const hasChildren = origin.find(floor => floor.upperFloorId === copyV.floorId);
                    copyV.disabled = !!(isSingleSelect && hasChildren);
                    return copyV;
                }),
            ),
        );
        setSelectedLabel(makeSelectedName(origin, selectedFloorList));
    }, [origin, selectedFloor, expandedFloor]);

    return (
        <>
            {innerRef && <Input type="hidden" name={formName} innerRef={innerRef} defaultValue={selectedFloor} />}
            <TreeSelect
                ref={treeDropdownRef}
                data={floorTreeList}
                onChange={onChange}
                onNodeToggle={onNodeToggle}
                showPartiallySelected={true}
                keepTreeOnSearch
                keepChildrenOnSearch
                showDropdown={showDropdown}
                mode={mode}
                // readOnly={true}
                texts={{
                    placeholder: selectedLabel || t('TreeSelect;Select floor'),
                    noMatches: t('TreeSelect;No matches found'),
                }}
            />
        </>
    );
};

export default React.memo(FloorTreeSelect);
