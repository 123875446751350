import React from 'react';
import { useDispatch } from 'react-redux';
import Card from '../Components/WidgetCard';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { setTempWidgetLayout } from '../../../reducers/Dashboards/DashboardEdit';
import { useTranslation } from 'react-i18next';

const TemporaryWidget = ({ children, widgetInfo, ...restProps }) => {
    const { type, layout } = widgetInfo;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleAddWidget = e => {
        dispatch(setTempWidgetLayout(layout));
    };

    return type === 'temp' ? (
        <Card widgetInfo={widgetInfo} {...restProps} className={'temp-widget'}>
            <div
                className={'flex-center'}
                style={{ border: '1px dashed #FFB930', height: '100%', color: '#777', fontSize: '1.25rem' }}
            >
                <Button
                    onClick={handleAddWidget}
                    style={{
                        backgroundColor: '#FFB930',
                        border: 'none',
                        color: 'black',
                        fontWeight: 700,
                        padding: '0.7rem',
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
                    {t('Widget;Add Widget')}
                </Button>
            </div>
            {children}
        </Card>
    ) : (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <div className={'flex-center'} style={{ height: '95%', color: '#777', fontSize: '1.25rem' }}>
                {t('Widget;This is not supported widget.')}
            </div>
            {children}
        </Card>
    );
};

export default TemporaryWidget;
