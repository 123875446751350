import { useState } from 'react';
import useAsync from '../../../util/hooks/useAsync';
import { fetchCategory } from '../../../api/asset';

export const useTopCategory = () => {
    const [categoryList, setCategoryList] = useState([]);
    useAsync({
        promise: fetchCategory,
        param: { isAll: 'Y' },
        immediate: true,
        resolve: res => {
            const { rows } = res;

            const categoryList = rows
                .filter(category => !category.depth)
                .map(topCategory => {
                    return { value: topCategory.categoryCode, label: topCategory.categoryName };
                });
            setCategoryList(categoryList);
        },
    });

    return categoryList;
};
