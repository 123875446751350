import React from 'react';
import WidgetSettingModal from '../Components/WidgetSettingModal';
import useTranslation from '../../../util/hooks/useTranslation';

const TagStatusSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation('TagStatus');
    return (
        <WidgetSettingModal
            headerTitle={t('Tag Status Setting')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            {...restProps}
        />
    );
};

export default TagStatusSetting;
