import React from 'react';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { useTranslation } from 'react-i18next';

const CongestionStatusSetting = ({ widgetInfo, ...restProps }) => {
    const { t } = useTranslation();
    return (
        <WidgetSettingModal
            headerTitle={t('Widget;Congestion Status')}
            widgetInfo={widgetInfo}
            socketWidget
            hiddenHeader
            {...restProps}
        />
    );
};

export default CongestionStatusSetting;
