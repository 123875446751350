import React from 'react';
import { Area, AreaChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Card from '../../../Common/Card';
import { useTranslation } from 'react-i18next';

const LineChartByTime = ({ dataForCongestion }) => {
    const { t } = useTranslation();
    return (
        <Card
            header={{
                title: t('HourlyCongestionStatus;Hourly congestion status'),
            }}
        >
            <ResponsiveContainer width={'100%'} height={'100%'}>
                <AreaChart data={dataForCongestion}>
                    <XAxis dataKey="time" />
                    <YAxis />
                    <Area
                        name="Total Entry Count"
                        type="monotone"
                        dataKey="totalEntry"
                        fill="#1F77B4"
                        stroke="#1F77B4"
                        fillOpacity={0.3}
                        activeDot={{ r: 4, fill: '#1F77B4' }}
                        dot={{ stroke: '#1F77B4' }}
                    />
                    <Area
                        name="Unique Entry Count"
                        type="monotone"
                        dataKey="uniqueEntry"
                        fill="#FF7F0E"
                        stroke="#FF7F0E"
                        fillOpacity={0.3}
                        activeDot={{ r: 4, fill: '#FF7F0E' }}
                        dot={{ stroke: '#FF7F0E' }}
                        connectNulls={true}
                    />
                    <Legend iconType={'rect'} iconSize={10} />
                    <Tooltip cursor={{ stroke: 'grey' }} />
                </AreaChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default LineChartByTime;
